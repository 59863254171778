import { Dayjs } from "dayjs";
import moment from "moment";

export const getRequiredDateFormat = (timeStamp, format = "MM-DD-YYYY") => {
  const formattedTimestamp = formatTimeStamp(timeStamp);
  return moment(formattedTimestamp).format(format);
};

export const getEpochTime = (date: Dayjs) => {
  const newDate = date.set("hour", 0).set("minute", 0).set("second", 0);
  return newDate.unix();
};

export const formatTimeStamp = (timeStamp) => {
  // Format the timestamp
  if (timeStamp < 1000000000000) {
    while (timeStamp < 1000000000000) {
      timeStamp = timeStamp * 10;
    }
  } else if (timeStamp > 9999999999999) {
    while (timeStamp > 9999999999999) {
      timeStamp = timeStamp / 10;
    }
  }

  return timeStamp;
};

export const customDayOfWeek = (day, date) => {
  if (date.locale() === "zh-cn") return day.replace("周", "");
  else return day.substring(0, 1).toUpperCase();
};
