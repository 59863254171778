import { useEffect, useContext } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

const GET_SUBTENANT_LICENSES = gql`
  query subTenantLicenses(
    $tenantId: ID!
    $page: PageInput
    $sort: [String]
    $filter: [String]
  ) {
    subTenantLicenses(
      tenantId: $tenantId
      page: $page
      sort: $sort
      filter: $filter
    ) {
      page {
        size
        number
        totalPages
        totalElements
      }
      licenses {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        startDate
        endDate
        users {
          current
          total
        }
        sites {
          current
          total
        }
        devices {
          current
          total
        }
        status
      }
    }
  }
`;

const ADD_LICENSE = gql`
  mutation addLicense($input: AddLicenseInput!) {
    addLicense(input: $input) {
      license {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        startDate
        endDate
        users {
          current
          total
        }
        sites {
          current
          total
        }
        devices {
          current
          total
        }
        status
      }
    }
  }
`;

const UPDATE_LICENSE = gql`
  mutation updateLicense($input: UpdateLicenseInput!) {
    updateLicense(input: $input) {
      license {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        startDate
        endDate
        users {
          current
          total
        }
        sites {
          current
          total
        }
        devices {
          current
          total
        }
        status
      }
    }
  }
`;

const useLicense = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    fetchSubTenantLicenses,
    {
      loading: isFetchSubTenantLicensesLoading,
      error: fetchSubTenantLicensesError,
      data: fetchSubTenantLicensesData,
    },
  ] = useLazyQuery(GET_SUBTENANT_LICENSES, {
    fetchPolicy: "no-cache",
  });

  const [
    addLicense,
    {
      loading: isAddLicenseLoading,
      error: addLicenseError,
      data: addLicenseResponseData,
      reset: resetAddLicenseData,
    },
  ] = useMutation(ADD_LICENSE);

  const [
    updateLicense,
    {
      loading: isUpdateLicenseLoading,
      error: updateLicenseError,
      data: updateLicenseResponseData,
      reset: resetUpdateLicenseData,
    },
  ] = useMutation(UPDATE_LICENSE);

  useEffect(() => {
    if (addLicenseError || updateLicenseError || fetchSubTenantLicensesError) {
      const error =
        addLicenseError || updateLicenseError || fetchSubTenantLicensesError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [addLicenseError, updateLicenseError, fetchSubTenantLicensesError]);

  return {
    fetchSubTenantLicenses,
    isFetchSubTenantLicensesLoading,
    fetchSubTenantLicensesError,
    fetchSubTenantLicensesData,
    addLicense,
    addLicenseError,
    isAddLicenseLoading,
    addLicenseResponseData,
    resetAddLicenseData,
    updateLicense,
    isUpdateLicenseLoading,
    updateLicenseError,
    updateLicenseResponseData,
    resetUpdateLicenseData,
  };
};

export default useLicense;
