import { useEffect, useContext } from "react";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { handleError } from "../utils";
import graphQLContext from "../context/graphQLContext";

export const GET_DEVICE_BY_ID = gql`
  query deviceById($tenantId: ID!, $id: ID!) {
    deviceById(tenantId: $tenantId, id: $id) {
      id
      name
      model {
        id
        name
        deviceType
        description
        status
      }
      type
      site {
        id
        name
        status
      }
      telemetry {
        name
        value
        unit
        type
        time
        seq
        writable
      }
      alarms {
        values
        description
        severity
        time
        status
      }
      alarmRules {
        id
        tenant {
          id
          name
          status
          createdOn
          updatedOn
        }
        name
        description
        emails
        properties
        severity
        updatedOn
        updatedBy {
          id
          firstName
          lastName
          email
          role
          status
        }
        status
      }
      status
    }
  }
`;

export const GET_DEVICES = gql`
  query devices(
    $tenantId: ID!
    $page: PageInput
    $sort: [String]
    $filter: [String]
  ) {
    devices(tenantId: $tenantId, page: $page, sort: $sort, filter: $filter) {
      page {
        size
        number
        totalPages
        totalElements
      }
      devices {
        id
        name
        model {
          id
          name
          deviceType
          description
          status
        }
        type
        site {
          id
          name
          status
        }
        telemetry {
          name
          value
          unit
          type
          time
          seq
          writable
        }

        status
      }
    }
  }
`;

const ADD_DEVICE = gql`
  mutation addDevice($input: AddDeviceInput!) {
    addDevice(input: $input) {
      device {
        id
        name
        model {
          id
          name
          deviceType
          description
          status
        }
        type
        site {
          id
          name
          status
        }
        status
      }
    }
  }
`;

const UPDATE_DEVICE = gql`
  mutation updateDevice($input: UpdateDeviceInput) {
    updateDevice(input: $input) {
      device {
        id
        name
        model {
          id
          name
          deviceType
          description
          status
        }
        type
        site {
          id
          name
          status
        }
        status
      }
    }
  }
`;

const useDevice = () => {
  const { uri }: any = useContext(graphQLContext);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [
    fetchDevices,
    { loading: isFetchLoading, error: fetchDeviceError, data: fetchDeviceData },
  ] = useLazyQuery(GET_DEVICES, {
    fetchPolicy: "no-cache",
  });

  const [
    fetchDeviceById,
    {
      loading: isFetchDeviceByIdLoading,
      error: fetchDeviceByIdError,
      data: fetchDeviceByIdData,
    },
  ] = useLazyQuery(GET_DEVICE_BY_ID, {
    fetchPolicy: "no-cache",
  });

  const [
    addDevice,
    {
      loading: isAddDeviceLoading,
      error: addDeviceError,
      data: addDeviceResponseData,
      reset: resetAddDeviceData,
    },
  ] = useMutation(ADD_DEVICE, {
    // Then re-run fetch
    refetchQueries: [GET_DEVICES],
  });

  const [
    updateDevice,
    {
      loading: isUpdateDeviceLoading,
      error: updateDeviceError,
      data: updateDeviceResponseData,
      reset: resetUpdateDeviceData,
    },
  ] = useMutation(UPDATE_DEVICE, {
    // Then re-run fetch
    refetchQueries: [GET_DEVICES],
  });

  // No straightforward way from apollo client to perform batch mutations
  // therefore, loop to call the apis one by one and wait for all promises to resolve
  // the function returns an array of responses
  // handle the responses individually

  // Add Multple Devices
  const batchCreation = async (allSubmitValues = []) => {
    return await Promise.all(
      allSubmitValues.map((submitValues) => {
        return addDevice({
          variables: {
            input: submitValues,
          },
        });
      })
    ).then((allResponses) => {
      return allResponses;
    });
  };

  // Update Multiple Devices
  const batchUpdate = async (allSubmitValues = []) => {
    return await Promise.all(
      allSubmitValues.map((submitValues) => {
        return updateDevice({
          variables: { input: submitValues },
        });
      })
    ).then((allResponses) => {
      return allResponses;
    });
  };

  useEffect(() => {
    if (
      fetchDeviceError ||
      addDeviceError ||
      updateDeviceError ||
      fetchDeviceByIdError
    ) {
      const error =
        fetchDeviceError ||
        addDeviceError ||
        updateDeviceError ||
        fetchDeviceByIdError;
      handleError(error, uri, enqueueSnackbar, intl);
    }
  }, [
    fetchDeviceError,
    addDeviceError,
    updateDeviceError,
    fetchDeviceByIdError,
  ]);

  return {
    isFetchLoading,
    fetchDeviceError,
    fetchDeviceData,
    fetchDevices,
    addDevice,
    isAddDeviceLoading,
    addDeviceError,
    addDeviceResponseData,
    resetAddDeviceData,
    updateDevice,
    isUpdateDeviceLoading,
    updateDeviceError,
    updateDeviceResponseData,
    resetUpdateDeviceData,
    batchUpdate,
    batchCreation,
    fetchDeviceById,
    isFetchDeviceByIdLoading,
    fetchDeviceByIdError,
    fetchDeviceByIdData,
  };
};

export default useDevice;
